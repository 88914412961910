/* colours */
$c-bg: #F3F3FA;
$c-text: #475569;
$c-text-light: #64748b;
$c-border: #f1f5f9;
$c-border-dark: #cbd5e1;
$c-primary: #73B0F4;
$c-dark: #1f2937;

// InspireMe
$c-light-purple: #e7cdde;
$c-mid-purple: #b86a9c;
$c-purple: #89085a;
$c-dark-purple: #7B0751;

$c-marine: #050a30;

$c-red: #d95565;
$c-green: #10b981;
$c-blue: #73B0F4;
$c-darkblue: #3A4488;
$c-orange: #F0AA61;
$c-tint: #FBFBFF;
$c-purple: #6363AC;
$c-darkpurple: #556CB6;

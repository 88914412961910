@import 'components/global.scss';

.user {

  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: $scale2;
  z-index: 10;

  @media (max-width: ($mobile - 1px)) {

    right: 50%;
    transform: translateX(50%);
    top: 65%;

  }
}

.name {

  float: right;
  opacity: 0.5;
  font-size: $scale-1;
  font-weight: 400;

  @media (max-width: ($mobile - 1px)) {

    float: unset;

  }
}

.hoverNav {
  
  @media (min-width: $mobile) {

    right: 0;
    text-align: right;

    nav:after {

      right: 0.5em;
      left: auto;

    }

    a, button {

      text-align: right;

    }
  }
}
@import 'components/global.scss';

.header {

  position: relative;
  width: auto;
  z-index: 3;
  background: white;
  padding: $scale2;
  font-weight: 600;
  margin: $scale2*-1 $scale2*-1 $scale2 $scale2*-1;

  @media (max-width: ($mobile - 1px)) {

    color: $c-text;
    text-align: center;
    background: white;
    padding: $scale;
    border: none;

    &[data-type="main"] {
      padding: $scale6;
    }

  }

  @media (min-width: $mobile) {

    h1 {

      font-size: $scale1;

    }
  }
}
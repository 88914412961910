@import 'components/global.scss';

.divider {
    margin: 2rem 0;
    border-bottom: 2px solid rgba(219, 219, 219, 0.5);
    color: rgba(138, 138, 138, 0.7);
    text-align: center;
    line-height: 0.2rem;
    font-style: italic;
    font-weight: 200;

    span {
        background:#fff; 
        padding: 0 0.8rem; 
        letter-spacing: 0.1rem;
    }
}
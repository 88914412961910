@import 'components/global.scss';

.date {

  &.clearable {

    display: flex;
    align-items: center;

  }
  
  &.range.clearable {

    min-width: 250px;

  }

  .dateWrapper {
  
    width: 100%;
    max-width: $scale13;
    margin-bottom: $scale1;
    z-index: unset;
  
    &:last-of-type {
  
      margin-bottom: 0;
  
    }
  
    > div {
  
      margin-top: -$scale5;
      z-index: 3;
  
    }
  
    input {
  
      margin-bottom: 0;
  
    }
  
    span {
  
      width: 2em !important;
  
    }
  
    + button {
  
      margin-left: -2.5rem;
      padding: $scale-2 $scale;
      z-index: 1;
  
    }
  
    &.open + button {
  
      z-index: 0;
  
    }
  }
}